import {
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useMemo, useRef, useState } from "react";
import { ModalType } from "constants/common";
import CreateAndUpdateModal from "./components/CreateAndUpdateModal";

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

function Kol() {
  const textColor = useColorModeValue("gray.700", "white");
  
  const [filter, setFilter] = useState(initialFilter);
  const [searchValue, setSearchValue] = useState("");
  const [detail, setDetail] = useState(null);

  const refSearchButton = useRef(null);
  
  const { isOpen: isCreateAndUpdateModalOpen, onOpen: onOpenCreateAndUpdateModal, onClose: onCloseCreateAndUpdateModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: isCreateAndUpdateModalOpen
    }),
    [isCreateAndUpdateModalOpen]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateAndUpdateModal,
    }),
    [onCloseCreateAndUpdateModal]
  );

  const refetch = () => {

  }

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setDetail(null);
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Danh sách Streamer
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm Tài khoản</FormLabel>
                      <InputGroup>
                        <InputRightElement pointerEvents="none">
                          <SearchIcon color="gray.300" />
                        </InputRightElement>
                        <Input
                          type="text"
                          variant="outline"
                          placeholder="Search by name"
                          value={searchValue}
                          onChange={(event) => setSearchValue(event.target.value)}
                          onKeyUp={(event) => {
                            if (event.key === "Enter" && event?.target?.value) {
                              const searchBtn = refSearchButton?.current;
                              if (searchBtn !== null) {
                                searchBtn.click();
                              }
                            }
                          }}
                        />
                      </InputGroup>
                    </FormControl>
                    <Button
                      ref={refSearchButton}
                      disabled={!searchValue}
                      colorScheme="blue"
                      alignSelf={'end'}
                      onClick={() => {
                        setFilter({
                          ...filter,
                          searchKeyword: searchValue,
                          pageIndex: 0,
                        });
                      }}
                    >
                      Search
                    </Button>
                    <Button
                     alignSelf={'end'}
                      onClick={() => {
                        setSearchValue("");
                        setFilter({
                          ...filter,
                          searchKeyword: "",
                          pageIndex: 0,
                        });
                      }}
                    >
                      Reset
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
            <Flex>
              <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateAndUpdateModal}>
                <Text fontSize="md" fontWeight="bold" cursor="pointer">
                  Thêm
                </Text>
              </Button>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">

        </CardBody>
      </Card>
      {isCreateAndUpdateModalOpen && (
        <CreateAndUpdateModal 
          detail={detail} 
          isOpen={isCreateAndUpdateModalOpen} 
          onClose={handelCloseModal} 
          refetch={refetch} 
        />
      )}
    </Flex>
  );
};

export default Kol;
