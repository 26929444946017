import { Box, Flex, FormControl, IconButton, Switch, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { TbTextResize } from 'react-icons/tb';
import { MdOutlineColorLens } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { formatDate } from 'utils/helpers';
import { useDeleteBannerMutation, useSetDefaultBannerMutation } from 'services/banner';

const BannerTable = ({ dataBanner, handleUpdateNews, refetch }) => {
  const history = useHistory();
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const setDefaultCategoryMutation = useSetDefaultBannerMutation();
  const deleteBannerMutation = useDeleteBannerMutation();

  const handleRowClick = (ticket, type) => {
    handleUpdateNews(ticket, type);
  };

  const handleDeleteSize = async banner => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa Banner này không?');
    if (!confirmDelete) {
      return;
    }
    deleteBannerMutation.mutate(
      { advertisementId: banner?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa Banner thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa Banner không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleBestBanner = async banner => {
    const actionText = banner.isBest ? 'Ẩn' : 'Hiển thị';
    const confirmMessage = window.confirm(`Bạn có chắc chắn muốn cài đặt Banner này?`);
    if (!confirmMessage) {
      return;
    }
    setDefaultCategoryMutation.mutate(
      { advertisementId: banner._id },
      {
        onSuccess: () => {
          const successMessage = `Banner này là Banner nổi bật thành công`;
          toast.showMessageSuccess(successMessage);
          refetch?.();
        },
        onError: () => {
          const errorMessage = `Banner này nổi bật không thành công`;
          // toast.showMessageError(errorMessage);
          refetch?.();
        },
      }
    );
  };

  const handleSwitchChange = banner => {
    handleBestBanner(banner);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('author.username', {
        header: 'Người tạo',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('banner', {
        header: 'Banner',
        cell: info => {
          const thumbnailUrl = info.row.original.banner;
          console.log('thumbnailUrl',thumbnailUrl);
          return thumbnailUrl ? (
            <img src={`${process.env.REACT_APP_API_HOST}/${thumbnailUrl}`} style={{ width: '80px', height: '80px', objectFit: 'contain' }} />
          ) : null;
        },
      }),
      columnHelper.accessor('is_default', {
        header: 'Mặt định',
        cell: info => ( 
          <FormControl display="flex" alignItems="center">
            <Switch isChecked={info.row.original.is_default} onChange={() => handleSwitchChange(info.row.original)} />
          </FormControl>
        ),
      }),

      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => formatDate(info.getValue()),
      }),

      columnHelper.accessor('action', {
        header: '',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            <IconButton
              bg="transparent"
              onClick={() => {
                handleRowClick(info?.row?.original, ModalType.Add);
              }}
            >
              <EditIcon cursor="pointer" boxSize={4} />
            </IconButton>
            <IconButton
              bg="transparent"
              onClick={() => {
                handleDeleteSize(info?.row?.original);
              }}
            >
              <DeleteIcon color="red.400" boxSize={4} />
            </IconButton>
          </Flex>
        ),
      }),
    ],
    [dataBanner]
  );

  const table = useReactTable({
    data: dataBanner || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default BannerTable;
