import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const createChannel = data => request.post('/api/v1/channel/create', data);
export const getListChannels = params => request.get(`/api/v1/channel/list`, { params });
export const getListMemberChannels = params => request.get(`/api/v1/channel/list-member/${params.channel_id}`, { params });
export const updateChannel = data => request.post('/api/v1/channel/update', data);
export const deleteChannel = data => request.post('/api/v1/channel/delete', data);
export const blockMemberChannel = data => request.post('/api/v1/channel/block-member-channel', data);
export const pinMessageChannel = data => request.post('/api/v1/channel/pin-message-bo', data);


// Mutation
export const useCreateChannelMutation = () => useMutation({ mutationFn: createChannel });
export const useUpdateChannelMutation = () => useMutation({ mutationFn: updateChannel });
export const useDeleteChannelMutation = () => useMutation({ mutationFn: deleteChannel });
export const useBlockMemberChannelMutation = () => useMutation({ mutationFn: blockMemberChannel });
export const usePinMessageMutation = () => useMutation({ mutationFn: pinMessageChannel });

// Query
export const useQueryGetListChannels = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListChannels', params], queryFn: () => getListChannels(params), ...options });
export const useQueryGetListMemberChannels = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListMemberChannels', params], queryFn: () => getListMemberChannels(params), ...options });
