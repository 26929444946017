import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';


export const getListInstant = params => request.get('/api/v1/instant/list-by-admin', { params });
export const createInstant = data => request.post('/api/v1/instant/create', data);
export const delteteInstant = data => request.post('/api/v1/instant/remove', data);

// Mutation
export const useCreateInstantMutation = () => useMutation({ mutationFn: createInstant });
export const useDelteteInstantMutation = () => useMutation({ mutationFn: delteteInstant });

// Query
export const useQueryGetListInstant = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListInstant', params], queryFn: () => getListInstant(params), ...options });
