import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const createPromotion = data => request.post('/api/v1/promotion/create', data);
export const getListPromotion = params => request.get('/api/v1/promotion/list', { params });
export const updatePromotion = data => request.post('/api/v1/promotion/update', data);

// Mutation
export const useCreatePromotionMutation = () => useMutation({ mutationFn: createPromotion });
export const useUpdatePromotionMutation = () => useMutation({ mutationFn: updatePromotion });

// Query
export const useQueryGetListPromotion = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_LIST_PROMOTION', params], queryFn: () => getListPromotion(params), ...options });