import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const createNews = data => request.post('/api/v1/news/create', data);
export const getListNews = params => request.get('/api/v1/news/list-admin', { params });
export const updateNews = data => request.post('/api/v1/news/update', data);

// Mutation
export const useCreateNewsMutation = () => useMutation({ mutationFn: createNews });
export const useUpdateNewsMutation = () => useMutation({ mutationFn: updateNews });

// Query
export const useQueryGetListNews = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_LIST_NEWS', params], queryFn: () => getListNews(params), ...options });