import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  FormLabel,
  Box,
  Grid,
  IconButton,
  Image,
  Text,
  GridItem,
  Flex,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { CreateVoucherFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateVoucherMutation, useQueryGetDetailVoucher, useUpdateVoucherMutation, useVoucherCodeGeneratorMutation } from 'services/voucher';
import NumericInputController from 'components/Form/NumericInputController';

const CreateVoucherModal = ({ isOpen, detail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createVoucherMutation = useCreateVoucherMutation();
  const voucherGenaratorMutation = useVoucherCodeGeneratorMutation();
  const updateVoucherMutation = useUpdateVoucherMutation();
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(CreateVoucherFormValidate),
    defaultValues: {
      code: '',
      percent: 0,
      amount: 0,
      description: ''
    },
  });

  useEffect(() => {
    if (detail) {
      reset({
        code: detail.code,
        percent: detail.percent,
        amount: detail.amount,
        description: detail.description,
      })
    }
  }, [detail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${detail ? 'Cập nhật' : 'Tạo'} Mã khuyến mãi thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${detail ? 'Cập nhật' : 'Tạo'} Mã khuyến mãi thành công`
    );
  };

  const onSubmit = async values => {
    if (detail) {
      updateVoucherMutation.mutate(
        {
          ...values,
          voucherId: detail._id
        },
        {
          onSuccess: () => {
            handleSuccess();
          },
          onError: error => handleError(error),
        }
      );
    } else {
      createVoucherMutation.mutate(
        values,
        {
          onSuccess: () => handleSuccess(),
          onError: error => handleError(error),
        }
      );
    }
  };

  const handleVoucherGenerator = () => {
    const payload = {
      "length": "8", // default: 6 ký tự
      "charset": "alphanumeric", // numbers, alphabetic, alphanumeric
      "pattern": "####-####" // default: #######
    };
    voucherGenaratorMutation.mutate(
      payload,
      {
        onSuccess: res => {
          if (res) {
            setValue('code', res?.data)
          }
        }
      }
    )
  }

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'450px'} >
          <AlertDialogHeader textTransform="uppercase">{detail ? 'Cập nhật' : 'Tạo'} Mã khuyến mãi</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody >
            <Flex alignItems={"end"}>
              {/* <InputController control={control} name="code" label="Mã khuyến mãi" type='text' isRequired /> */}
              <Controller
                name="code"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isRequired isInvalid={error && error?.message}>
                    <FormLabel minW="150px">
                      Mã khuyến mã
                    </FormLabel>
                    <Flex flexDirection="column">
                      <Flex>
                        <Input
                          {...field}
                          type="text"
                          onChange={e => {
                            field.onChange(e);
                          }}
                        />
                        <Button onClick={handleVoucherGenerator}>Tạo mã</Button>
                      </Flex>
                      <FormErrorMessage>{error && error?.message}</FormErrorMessage>
                    </Flex>
                  </FormControl>
                )}
              />

            </Flex>
            <NumericInputController
              control={control}
              name="percent"
              label="Phần trăm khuyến mãi"
              type='text'
              styleContainer={{ marginTop: "8px" }}
            />
            <NumericInputController control={control} name="amount" label="Số lượng mã" type='text' styleContainer={{ marginTop: "8px" }} />
            <InputController control={control} name="description" label="Mô tả" type='textarea' styleContainer={{ marginTop: "8px" }} />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createVoucherMutation.isPending || createVoucherMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {detail ? 'Cập nhập' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateVoucherModal;