import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ModalType } from 'constants/common';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CreateUpdateModal from './components/CreateUpdateModal';
import { useQueryGetListChannels } from 'services/channel';
import { CookieStorage } from 'utils/cookie-storage';
import ChannelTable from './components/ChannelTable';
import Pagination from 'components/Pagination/Pagination';

function Channel() {
  const textColor = useColorModeValue('gray.700', 'white');
  const [searchTitle, setSearchTitle] = useState('');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    searchKeyword:''
  });
  const [detail, setDetail] = useState(null);
  const { isOpen: isCreateUpdateModalOpen, onOpen: onOpenCreateUpdateModal, onClose: onCloseCreateUpdateModal } = useDisclosure();
  const { data: channels, refetch } = useQueryGetListChannels(
    { ...filter },
    { enabled: CookieStorage.isAuthenticated() }
  );

  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateUpdateModal,
    }),
    [onOpenCreateUpdateModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateUpdateModal,
    }),
    [onCloseCreateUpdateModal]
  );

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setDetail(null);
  };

  const handleUpdateItem = (item, modalType) => {
    openModal?.[modalType]?.();
    setDetail(item);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
    });
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Danh sách Nhóm Chat
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm Nhóm Chat</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <Button variant="primary" maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
            <Flex>
              <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateUpdateModal}>
                <Text fontSize="md" fontWeight="bold" cursor="pointer">
                  Thêm
                </Text>
              </Button>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <ChannelTable
              channels={channels?.data || []}
              handleUpdateNews={handleUpdateItem}
              refetch={refetch}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={channels?.pagination?.page}
              pageLength={channels?.pagination?.pageSize}
              totalRecords={channels?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isCreateUpdateModalOpen && (
        <CreateUpdateModal
          detail={detail}
          isOpen={isCreateUpdateModalOpen}
          onClose={handelCloseModal}
          refetch={refetch}
        />
      )}
    </Flex>
  );
};

export default Channel;