import { Avatar, Box, Flex, FormControl, IconButton, Switch, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon, InfoIcon } from '@chakra-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { ModalType, ROOT_API } from 'constants/common';
import { toast } from 'components/Toast';
import { useDeleteChannelMutation } from 'services/channel';
import { formatDate } from 'utils/helpers';
import { useHistory } from 'react-router-dom';

function ChannelTable({ channels, handleUpdateNews, refetch }) {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const deleteChannelMutation = useDeleteChannelMutation();
  const history = useHistory();

  const handleRowClick = (ticket, type) => {
    handleUpdateNews(ticket, type);
  };

  const handleDelete = async channel => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa nhóm chat này không?');
    if (!confirmDelete) {
      return;
    }
    deleteChannelMutation.mutate(
      { channelId: channel?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa nhóm chat thành công');
          refetch?.();
        },
        onError: () => {
          // toast.showMessageError('Xóa nhóm chat không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('avatar', {
        header: <Flex width="100%" flexWrap="nowrap" justifyContent="start">{'Ảnh'}</Flex>,
        cell: info => {
          return (
            <Flex align="center" justifyContent="start" py=".8rem" width="100%" flexWrap="nowrap">
              <Avatar src={`${ROOT_API}/${info.renderValue()}`} style={{ width: '50px', height: '50px', objectFit: 'contain' }} />
            </Flex>
          );
        },
      }),

      columnHelper.accessor('title', {
        header: 'Tên nhóm chat',
        cell: info => info.renderValue(),
      }),

      columnHelper.accessor('author.username', {
        header: 'Người tạo',
        cell: info => info.renderValue(),
      }),

      columnHelper.accessor('description', {
        header: 'Mô tả',
        cell: info => info.renderValue(),
      }),

      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => formatDate(info.getValue()),
      }),

      columnHelper.accessor('action', {
        header: '',
        cell: info => (
          <Flex alignItems="center" gap={1}>
             <IconButton
              bg="transparent"
              onClick={() => {
                history.push(`/admin/list-member-channel?channel_id=${info?.row?.original?._id}`);
              }}
            >
              <InfoIcon cursor="pointer" boxSize={4} />
            </IconButton>
            <IconButton
              bg="transparent"
              onClick={() => {
                handleRowClick(info?.row?.original, ModalType.Add);
              }}
            >
              <EditIcon cursor="pointer" boxSize={4} />
            </IconButton>
            <IconButton
              bg="transparent"
              onClick={() => {
                handleDelete(info?.row?.original);
              }}
            >
              <DeleteIcon color="red.400" boxSize={4} />
            </IconButton>
          </Flex>
        ),
      }),
    ],
    [channels]
  );

  const table = useReactTable({
    data: channels || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default ChannelTable;