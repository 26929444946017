import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const uploadMedias = (data, config) =>
  request.post('/api/v1/medias/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });

  // Mutation
export const useUploadMediasMutation = () => useMutation({ mutationFn: uploadMedias });