import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  FormLabel,
  Box,
  Image,
  Text,
  Switch,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { ModalType, ROOT_API } from 'constants/common';
import { toast } from 'components/Toast';
import { CreateBannerFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUploadMediasMutation } from 'services/media';
import { useCreateBannerMutation, useUpdateBannerMutation } from 'services/banner';

const CreateModal = ({ isOpen, detail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createBannerMutation = useCreateBannerMutation();
  const [file, setFile] = useState();
  const updateThumnailMutation = useUploadMediasMutation();
  const updateBannerMutation = useUpdateBannerMutation();
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateBannerFormValidate),
    defaultValues: {
      banner:"",
      is_default: false,
    },
  });

  useEffect(() => {
    if (detail) {
      reset({
        banner: detail.banner,
        is_default: detail.is_default,
      });
      const text = detail.banner.split('\\');
      setFile(detail.banner);
      // setFile(
      //   id: nanoid(),
      //   preview: ROOT_API + '/' + detail.banner,
      //   path: detail.banner,
      // )
    }
  }, [detail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${detail ? 'Cập nhật' : 'Tạo'} banner thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${detail ? 'Cập nhật' : 'Tạo'} banner không thành công`
    );
  };

  const onSubmit = async values => {
    if (!file) {
      setError('banner', { type: 'custom', message: 'Vui lòng chọn banner.' });
      return;
    }


    if (detail) {
      updateBannerMutation.mutate(
        {
          ...values,
          advertisementId: detail._id
        },
        {
          onSuccess: () => {
            handleSuccess();
          },
          onError: error => handleError(error),
        }
      );
    } else {
      createBannerMutation.mutate(
        values,
        {
          onSuccess: () => handleSuccess(),
          onError: error => handleError(error),
        }
      );
    }
  };

  const handleFileChange = (e, field) => {
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append('files', e.target.files[0]);
      updateThumnailMutation.mutate(formData, {
        onSuccess: res => {
          setFile(e.target.files[0]);
          field.onChange(res?.data[0]);
        },
      });
    }
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'450px'}>
          <AlertDialogHeader textTransform="uppercase">{detail ? 'Cập nhật' : 'Tạo'} Banner</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
          <Controller
              control={control}
              name="is_default"
              render={({ field }) => (
                <FormControl display="flex" alignItems="center" mt={4}>
                  <FormLabel htmlFor="is_default" mb="0">
                    Mặc định
                  </FormLabel>
                  <Switch id="is_default" {...field} isChecked={field.value} />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="banner"
              render={({ field, fieldState: { error } }) => (
                <div className="z-0">
                  <Text marginTop={'8px'}>Banner</Text>
                  <div className="file-upload">
                    <div className="file-select font-montserrat rounded-lg">
                      <div className="file-select-button" id="fileName">
                        Chọn tệp
                      </div>
                      {/* <div className="file-select-name" id="noFile">
                        <div>
                          {file ? typeof file === 'string' ? name : file?.name : `Không có tệp nào được chọn`}
                        </div>
                      </div> */}
                      <input type="file" name="chooseFile" id="chooseFile" onChange={e => handleFileChange(e, field)} />
                    </div>
                  </div>
                  {file && (
                    <Box marginTop={'8px'}>
                      <Image
                        src={typeof file === 'string' ? `${ROOT_API + '/' + file}` : URL.createObjectURL(file)}
                        w="full"
                        h="full"
                        objectFit="contain"
                      />
                    </Box>
                  )}
                  {errors && errors['banner'] && <div className="text-danger mt-2">{errors['banner'].message}</div>}
                </div>
              )}
            />
          
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createBannerMutation.isPending || updateBannerMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {detail ? 'Cập nhập' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateModal;
