import React, { useEffect, useState } from 'react';
import { Flex, Button, Text, useColorModeValue, Box, Grid, GridItem, } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { yupResolver } from '@hookform/resolvers/yup';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { useForm } from 'react-hook-form';
import CheckboxController from 'components/Form/CheckboxController';
import { usePinMessageMutation, useQueryGetListChannels } from 'services/channel';
import { PinMessageFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';

const PinMessage = () => {
  const textColor = useColorModeValue('gray.700', 'white');
  const [messageChanel, setMessageChannel] = useState([]);
  const { data: dataMessagesOptions, refetch } = useQueryGetListChannels();
  const pinMessageChannelMutation = usePinMessageMutation();

  useEffect(() => {
    if (dataMessagesOptions?.data) {
      setMessageChannel(
        dataMessagesOptions.data.map(item => ({
          value: item._id,
          label: item.title,
        }))
      );
    }
  }, [dataMessagesOptions?.data]);

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(PinMessageFormValidate),
    defaultValues: {
      channelId: '',
      type_message: 'TEXT',
      message: '',
      is_pin: false,
    },
  });

  const handleSuccess = () => {
    toast.showMessageSuccess(`Tạo pin message thành công`);
    refetch?.();
  };
  const handleError = error => {
    toast.showMessageError(error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Tạo pin message không thành công`);
  };

  const onSubmit = data => {
    pinMessageChannelMutation.mutate(
      { ...data, channelId: data.channelId.value },
      {
        onSuccess: () => {
            handleSuccess()
            reset()
        },
        onError: error => handleError(error),
      }
    );
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Create Pin Message
            </Text>
          </CardHeader>
          <CardBody pb={4}>
            <form>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem>
                  <SelectController
                    styleBoxInput={{
                      flexShrink: '1',
                      flexGrow: '1',
                      flexBasis: '0',
                    }}
                    control={control}
                    options={messageChanel}
                    name="channelId"
                    label="Nhóm chát"
                    isRequired
                  />
                </GridItem>
              </Grid>
              <Grid mt={4}>
                <GridItem>
                  <CheckboxController
                    styleContainer={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    minWLabel="40px"
                    control={control}
                    name="is_pin"
                    label="Pin tin nhắn"
                  />
                </GridItem>
              </Grid>
              <Grid mt={4}>
                <GridItem>
                  <InputController control={control} name="message" label="Thông tin" type="textarea" />
                </GridItem>
              </Grid>
            </form>
            <Flex mt={3} columnGap={3} justifyContent={'end'}>
              <Box>
                <Button
                  colorScheme="blue"
                  isLoading={pinMessageChannelMutation.isPending }
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </Button>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default PinMessage;
