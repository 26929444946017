import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const createBanner = data => request.post('/api/v1/advertisement/create', data);
export const updateBanner = data => request.post('/api/v1/advertisement/update', data);
export const deleteBanner = data => request.post('/api/v1/advertisement/delete', data);
export const setDefaultBanner = data => request.post('/api/v1/advertisement/set-default', data);
export const getListBanner = data => request.get('/api/v1/advertisement/list', data);

// Mutation
export const useCreateBannerMutation = () => useMutation({ mutationFn: createBanner });
export const useUpdateBannerMutation = () => useMutation({ mutationFn: updateBanner });
export const useDeleteBannerMutation = () => useMutation({ mutationFn: deleteBanner });
export const useSetDefaultBannerMutation = () => useMutation({ mutationFn: setDefaultBanner });

// Query
export const useQuerygetListBanner = (params = {}, options = {}) =>
  useQuery({ queryKey: ['getListBanner', params], queryFn: () => getListBanner(params), ...options });
