import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  FormLabel,
  Box,
  Grid,
  IconButton,
  Image,
  Text,
  GridItem,
  Flex,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import { ModalType, ROOT_API } from 'constants/common';
import { toast } from 'components/Toast';
import { nanoid } from 'nanoid';
import { useUpdateCategoryMutation, useCreateCategoryMutation, useUpdateThumbnailMutation } from 'services/category';
import { CreatePromotionFormValidate, CreateVoucherFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { uploadMedias, useUploadMediasMutation } from 'services/media';
import { isEmpty } from 'lodash';
import NumericInputController from 'components/Form/NumericInputController';
import { useCreatePromotionMutation, useUpdatePromotionMutation } from 'services/promotion';

const CreateModal = ({ isOpen, detail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createPromotionMutation = useCreatePromotionMutation();
  const [file, setFile] = useState();
  const updateThumnailMutation = useUploadMediasMutation();
  const updatePromotionMutation = useUpdatePromotionMutation();
  const { control, handleSubmit, reset, setValue, setError, formState: { errors } } = useForm({
    resolver: yupResolver(CreatePromotionFormValidate),
    defaultValues: {
      code: '',
      percent: 0,
      amount: 0,
      description: ''
    },
  });

  useEffect(() => {
    if (detail) {
      reset({
        title: detail.title,
        percent: detail.percent,
        description: detail.description,
        banner: detail.banner
      });
      const text = detail.banner.split("\\");
      setFile(detail.banner)
      // setFile(
      //   id: nanoid(),
      //   preview: ROOT_API + '/' + detail.banner,
      //   path: detail.banner,
      // )
    }
  }, [detail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${detail ? 'Cập nhật' : 'Tạo'} Ưu đãi thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${detail ? 'Cập nhật' : 'Tạo'} Ưu đãi thành công`
    );
  };

  const onSubmit = async values => {
    if (!file) {
      setError('banner', { type: 'custom', message: 'Vui lòng chọn banner.'});
      return;
    }

    if (detail) {
      updatePromotionMutation.mutate(
        {
          ...values,
          promotionId: detail._id
        },
        {
          onSuccess: () => {
            handleSuccess();
          },
          onError: error => handleError(error),
        }
      );
    } else {
      createPromotionMutation.mutate(
        values,
        {
          onSuccess: () => handleSuccess(),
          onError: error => handleError(error),
        }
      );
    }
  };

  const handleFileChange = (e, field) => {
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append('files', e.target.files[0])
      updateThumnailMutation.mutate(
        formData,
        {
          onSuccess: (res) => {
            setFile(e.target.files[0])
            field.onChange(res?.data[0])
          },
        }
      )
    }
  }

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'450px'} >
          <AlertDialogHeader textTransform="uppercase">{detail ? 'Cập nhật' : 'Tạo'} Mã khuyến mãi</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody >
            <InputController control={control} name="title" label="Tiêu đề" type='text' isRequired />
            <NumericInputController
              control={control}
              name="percent"
              label="Phần trăm khuyến mãi"
              type='text'
              styleContainer={{ marginTop: "8px" }}
            />
            <InputController control={control} name="description" label="Mô tả" type='textarea' styleContainer={{ marginTop: "8px" }} />
            <Controller
              control={control}
              name="banner"
              render={({ field, fieldState: { error } }) => (
                <div className='z-0'>
                  <Text marginTop={"8px"}>Banner</Text>
                  <div className="file-upload">
                    <div className="file-select font-montserrat rounded-lg">
                      <div className="file-select-button" id="fileName">Chọn tệp</div>
                      {/* <div className="file-select-name" id="noFile">
                        <div>
                          {file ? typeof file === 'string' ? name : file?.name : `Không có tệp nào được chọn`}
                        </div>
                      </div> */}
                      <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => handleFileChange(e, field)} />
                    </div>

                  </div>
                  {file && (
                    <Box marginTop={"8px"}>
                      <Image src={typeof file === 'string' ? `${ROOT_API + '/' + file}` : URL.createObjectURL(file)} w="full" h="full" objectFit="contain" />
                    </Box>
                  )}
                  {errors && errors["banner"] && <div className="text-danger mt-2">{errors["banner"].message}</div>}
                </div>
              )}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createPromotionMutation.isPending || updatePromotionMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {detail ? 'Cập nhập' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateModal;