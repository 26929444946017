import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Image,
  Text,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import { toast } from 'components/Toast';
import { ModalType, Roles, ROOT_API } from 'constants/common';
import InputController from 'components/Form/InputController';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterMemberFormValidate } from 'utils/validation';
import { useUploadMediasMutation } from 'services/media';
import { useChangeInfoMemberMutation, useRegisterMemberMutation } from 'services/purchase-history';

function CreateModal({ isOpen, detail, onClose, refetch }) {
  const cancelRef = useRef();
  const [file, setFile] = useState();
  const [show, setShow] = useState({
    password: false,
  });
  const updateThumnailMutation = useUploadMediasMutation();
  const registerMemberMutation = useRegisterMemberMutation();
  const updateChangeInfoMutation = useChangeInfoMemberMutation();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RegisterMemberFormValidate(detail)),
    defaultValues: {
      username: '',
      password: '',
      phoneNumber: '',
      avatar: '',
      role: 'ADMIN',
    },
  });

  useEffect(() => {
    if (detail) {
      reset({
        username: detail.username,
        phoneNumber: detail.phoneNumber,
        avatar: detail.avatar,
        role: detail.role,
      });
    }
  }, [detail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${detail ? 'Cập nhật' : 'Tạo'} Thành viên thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg ? error?.response?.data?.msg : `${detail ? 'Cập nhật' : 'Tạo'} thành viên thất bại.`
    );
  };

  const handleFileChange = (e, field) => {
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append('files', e.target.files[0]);
      updateThumnailMutation.mutate(formData, {
        onSuccess: res => {
          setFile(e.target.files[0]);
          field.onChange(res?.data[0]);
        },
      });
    }
  };

  const onSubmit = async values => {
    console.log('Vai trò',values);
    if (detail) {
      updateChangeInfoMutation.mutate(
        {
          ...values,
        },
        {
          onSuccess: () => {
            handleSuccess();
          },
          // onError: error => handleError(error),
        }
      );
    } else {
      if (!file) {
        setError('avatar', { type: 'custom', message: 'Vui lòng chọn avatar.' });
        return;
      }
      registerMemberMutation.mutate(
        { ...values },
        {
          onSuccess: () => handleSuccess(),
          // onError: error => handleError(error),
        }
      );
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW={'450px'}>
        <AlertDialogHeader textTransform="uppercase">{detail ? 'Cập nhật' : 'Tạo'}Tài khoản</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <InputController control={control} name="username" label="Username" type="text" styleContainer={{ marginTop: '8px' }} />
          {!detail && (
            <InputController
              control={control}
              type={show.password ? 'text' : 'password'}
              name="password"
              label="Password"
              isRequired
              styleContainer={{ marginBottom: '15px' }}
              styleBoxInput={{ flex: 1 }}
              inputRightElement={
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={() =>
                    setShow({
                      ...show,
                      password: !show.password,
                    })
                  }
                >
                  {show.password ? 'Hide' : 'Show'}
                </Button>
              }
            />
          )}
          {!detail && (
            <InputController control={control} name="phoneNumber" label="Số điện thoại" type="text" styleContainer={{ marginTop: '8px' }} />
          )}
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Box marginTop="8px">
                <Text marginBottom="4px">Vai trò</Text>
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="ADMIN">ADMIN</Radio>
                    <Radio value="SUPPORTER">SUPPORTER</Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            )}
          />
          <Controller
            control={control}
            name="avatar"
            render={({ field, fieldState: { error } }) => (
              <div className="z-0">
                <Text marginTop={'8px'}>Avatar</Text>
                <div className="file-upload">
                  <div className="file-select font-montserrat rounded-lg">
                    <div className="file-select-button" id="fileName">
                      Chọn tệp
                    </div>
                    {/* <div className="file-select-name" id="noFile">
                        <div>
                          {file ? typeof file === 'string' ? name : file?.name : `Không có tệp nào được chọn`}
                        </div>
                      </div> */}
                    <input type="file" name="chooseFile" id="chooseFile" onChange={e => handleFileChange(e, field)} />
                  </div>
                </div>
                {field.value && (
                  <Box marginTop={'8px'}>
                    <Image
                      src={field.value ? `${ROOT_API + '/' + field.value}` : URL.createObjectURL(file)}
                      w="full"
                      h="full"
                      objectFit="contain"
                    />
                  </Box>
                )}
                {errors && errors['avatar'] && <div className="text-danger mt-2">{errors['avatar'].message}</div>}
              </div>
            )}
          />
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose(ModalType.Add);
            }}
          >
            Hủy
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            isLoading={registerMemberMutation.isPending || updateChangeInfoMutation.isPending}
            onClick={handleSubmit(onSubmit)}
          >
            {detail ? 'Cập nhập' : 'Tạo'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default CreateModal;
