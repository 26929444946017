import React from 'react';
import { IoBagCheckSharp, IoBook, IoCar, IoListCircleOutline, IoChatboxEllipsesOutline, IoEarth } from 'react-icons/io5';
import { AiOutlineShoppingCart } from "react-icons/ai";
import { HomeIcon, PersonIcon } from 'components/Icons/Icons';
import { BiFoodMenu, BiMessageAltX, BiMessageDetail } from "react-icons/bi";
import Dashboard from 'views/Dashboard/Dashboard.js';
import SignUp from 'views/Pages/SignUp.js';
import SignIn from 'views/Pages/SignIn';
import Profile from 'views/Dashboard/Profile';
import { Roles } from 'constants/common';
import Size from 'views/Dashboard/Size';
import Colors from 'views/Dashboard/Colors';
import Product from 'views/Dashboard/Product';
import ProductForm from 'views/Dashboard/Product/components/ProductForm';
import ShippingFee from 'views/Dashboard/ShippingFee/ShippingFee';
import Category from 'views/Dashboard/Category/Category';
import PurchaseHistory from 'views/Dashboard/PurchaseHistory/PurchaseHistory';
import Members from 'views/Dashboard/Members/Members';
import Comment from 'views/Dashboard/Comment';
import Province from 'views/Dashboard/Province';
import Orders from 'views/Dashboard/Orders';
import News from 'views/Dashboard/News/News';
import Voucher from 'views/Dashboard/Voucher/Voucher';
import Promotion from 'views/Dashboard/Promotion/Promotion';
import Supporter from 'views/Dashboard/Supporter/Supporter';
import Kol from 'views/Dashboard/Kol/koi';
import Instant from 'views/Dashboard/Instant';
import Channel from 'views/Dashboard/Channel/Channel';
import Banner from 'views/Dashboard/Banner';
import ListMemberChannel from 'views/Dashboard/ListMemberChannel';
import PinMessage from 'views/Dashboard/PinMessage';

var dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: <HomeIcon color="inherit" />,
    layout: '/admin',
    component: Dashboard,
    role: [Roles.ADMIN],
  },
  {
    path: '/members',
    name: 'Người dùng',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Members,
    role: [Roles.ADMIN],
  },
  // {
  //   path: '/category/:id/size',
  //   name: 'Size',
  //   icon: <PersonIcon color="inherit" />,
  //   redirect: true,
  //   layout: '/admin',
  //   component: Size,
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: '/category/:id/colors',
  //   name: 'Colors',
  //   icon: <PersonIcon color="inherit" />,
  //   redirect: true,
  //   layout: '/admin',
  //   component: Colors,
  // },
  {
    path: '/supporter',
    name: 'Chăm sóc khách hàng',
    icon: <IoBook color="inherit" />,
    layout: '/admin',
    component: Supporter,
    role: [Roles.ADMIN],
  },
  {
    path: '/commentator',
    name: 'Streamer',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    component: Kol,
    role: [Roles.ADMIN],
  },
  {
    path: '/channel',
    name: 'Nhóm chat',
    icon: <BiMessageDetail color="inherit" />,
    layout: '/admin',
    component: Channel,
    role: [Roles.ADMIN],
  },
  {
    path: '/list-member-channel',
    name: 'Danh sách thành viên',
    icon: <BiFoodMenu color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: ListMemberChannel,
    role: [Roles.ADMIN],
  },
  {
    path: '/news',
    name: 'Tin tức',
    icon: <IoBook color="inherit" />,
    layout: '/admin',
    component: News,
    role: [Roles.ADMIN],
  },
  {
    path: '/pin-message',
    name: 'Pin Message',
    icon: <IoBook color="inherit" />,
    layout: '/admin',
    component: PinMessage,
    role: [Roles.ADMIN],
  },
  {
    path: '/voucher',
    name: 'Mã khuyến mãi',
    icon: <IoBook color="inherit" />,
    layout: '/admin',
    component: Voucher,
    role: [Roles.ADMIN],
  },
  {
    path: '/promotion',
    name: 'Ưu đãi',
    icon: <IoBook color="inherit" />,
    layout: '/admin',
    component: Promotion,
    role: [Roles.ADMIN],
  },
  {
    path: '/instant',
    name: 'Khoảnh khắc',
    icon: <IoBook color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: Instant,
    role: [Roles.ADMIN],
  },
  {
    path: '/banner',
    name: 'Banner',
    icon: <IoBook color="inherit" />,
    layout: '/admin',
    redirect: true,
    component: Banner,
    role: [Roles.ADMIN],
  },

  // {
  //   path: '/product/create',
  //   name: 'Tạo sản phẩm',
  //   icon: <IoListCircleOutline size={20} />,
  //   redirect: true,
  //   layout: '/admin',
  //   component: ProductForm,
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: '/product/:id',
  //   name: 'Chi tiết sản phẩm',
  //   icon: <IoListCircleOutline size={20} />,
  //   redirect: true,
  //   layout: '/admin',
  //   component: ProductForm,
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: '/product',
  //   name: 'Sản phẩm',
  //   icon: <IoListCircleOutline size={20} />,
  //   layout: '/admin',
  //   component: Product,
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: '/order',
  //   name: 'Đơn hàng',
  //   icon: <AiOutlineShoppingCart size={20} />,
  //   layout: '/admin',
  //   component: Orders,
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: '/shipping',
  //   name: 'Phí Vận Chuyển',
  //   icon: <IoCar size={20} />,
  //   layout: '/admin',
  //   component: ShippingFee,
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: '/purchase',
  //   name: 'Lịch sử mua hàng',
  //   icon: <IoBagCheckSharp color="inherit" />,
  //   layout: '/admin',
  //   component: PurchaseHistory,
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: '/comment',
  //   name: 'Bình luận',
  //   icon: <IoChatboxEllipsesOutline  size={20} />,
  //   layout: '/admin',
  //   component: Comment,
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: '/province',
  //   name: 'Quốc gia',
  //   icon: <IoEarth   size={20} />,
  //   layout: '/admin',
  //   component: Province,
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: '/category/:id/size',
  //   name: 'Size',
  //   icon: <PersonIcon color="inherit" />,
  //   redirect: true,
  //   layout: '/admin',
  //   component: Size,
  //   role: [Roles.ADMIN],
  // },
  // {
  //   path: '/category/:id/colors',
  //   name: 'Colors',
  //   icon: <PersonIcon color="inherit" />,
  //   redirect: true,
  //   layout: '/admin',
  //   component: Colors,
  //   role: [Roles.ADMIN],
  // },
  {
    name: 'ACCOUNT PAGES',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
      {
        path: '/profile',
        name: 'Profile',
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        layout: '/admin',
        component: Profile,
        role: [Roles.ADMIN, Roles.USER, Roles.GUEST],
      },
      {
        path: '/sign-in',
        layout: '/auth',
        redirect: true,
        component: SignIn,
      },
      {
        path: '/sign-up',
        layout: '/auth',
        redirect: true,
        component: SignUp,
      },
    ],
  },
];

export default dashRoutes;
