import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  FormLabel,
  Box,
  Grid,
  IconButton,
  Image,
  Text,
  GridItem,
  Flex,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';
import { toast } from 'components/Toast';
import { ModalType, ROOT_API } from 'constants/common';
import InputController from 'components/Form/InputController';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateUpdateChannelFormValidate } from 'utils/validation';
import { useUploadMediasMutation } from 'services/media';
import { useCreateChannelMutation, useUpdateChannelMutation } from 'services/channel';

function CreateUpdateModal({ isOpen, detail, onClose, refetch }) {
  const cancelRef = useRef();
  const [file, setFile] = useState();
  const updateThumnailMutation = useUploadMediasMutation();
  const createChannelMutation = useCreateChannelMutation();
  const updateVoucherMutation = useUpdateChannelMutation();
  const { control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(CreateUpdateChannelFormValidate),
    defaultValues: {
      title: '',
      description: '',
      avatar: ''
    },
  });

  useEffect(() => {
    if (detail) {
      reset({
        title: detail.title,
        description: detail.description,
        avatar: detail.avatar
      })
    }
  }, [detail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${detail ? 'Cập nhật' : 'Tạo'} nhóm chat thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg ? error?.response?.data?.msg : `${detail ? 'Cập nhật' : 'Tạo'} nhóm chat thất bại.`
    );
  };

  const handleFileChange = (e, field) => {
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append('files', e.target.files[0])
      updateThumnailMutation.mutate(
        formData,
        {
          onSuccess: (res) => {
            setFile(e.target.files[0])
            field.onChange(res?.data[0])
          },
        }
      )
    }
  }

  const onSubmit = async values => {
    if (detail) {
      updateVoucherMutation.mutate(
        {
          ...values,
          channelId: detail._id
        },
        {
          onSuccess: () => {
            handleSuccess();
          },
          // onError: error => handleError(error),
        }
      );
    } else {
      createChannelMutation.mutate(
        values,
        {
          onSuccess: () => handleSuccess(),
          // onError: error => handleError(error),
        }
      );
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW={'450px'} >
        <AlertDialogHeader textTransform="uppercase">{detail ? 'Cập nhật' : 'Tạo'} Nhóm chat</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody >
          <Controller
            control={control}
            name="avatar"
            render={({ field, fieldState: { error } }) => (
              <div className='z-0'>
                <Text marginTop={"8px"}>Banner</Text>
                <div className="file-upload">
                  <div className="file-select font-montserrat rounded-lg">
                    <div className="file-select-button" id="fileName">Chọn tệp</div>
                    {/* <div className="file-select-name" id="noFile">
                        <div>
                          {file ? typeof file === 'string' ? name : file?.name : `Không có tệp nào được chọn`}
                        </div>
                      </div> */}
                    <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => handleFileChange(e, field)} />
                  </div>

                </div>
                {field.value && (
                  <Box marginTop={"8px"}>
                    <Image src={field.value ? `${ROOT_API + '/' + field.value}` : URL.createObjectURL(file)} w="full" h="full" objectFit="contain" />
                  </Box>
                )}
                {errors && errors["avatar"] && <div className="text-danger mt-2">{errors["banner"].message}</div>}
              </div>
            )}
          />
          <InputController
            control={control}
            name="title"
            label="Tên nhóm chat"
            type='text'
            styleContainer={{ marginTop: "8px" }}
          />
          <InputController
            control={control}
            name="description"
            label="Mô tả"
            type='textarea'
            styleContainer={{ marginTop: "8px" }}
          />
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose(ModalType.Add);
            }}
          >
            Hủy
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            isLoading={createChannelMutation.isPending || updateVoucherMutation.isPending}
            onClick={handleSubmit(onSubmit)}
          >
            {detail ? 'Cập nhập' : 'Tạo'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateUpdateModal;