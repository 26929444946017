import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  FormLabel,
  Box,
  Grid,
  IconButton,
  Image,
  Text,
  GridItem,
  Checkbox,
  Stack,
  HStack,
  Input,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import { ModalType, ROOT_API } from 'constants/common';
import { toast } from 'components/Toast';
import { nanoid } from 'nanoid';
import { useCreateNewsMutation, useUpdateNewsMutation } from 'services/news';
import { CategoryFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { uploadMedias } from 'services/media';
import { isEmpty } from 'lodash';
import { useCreateInstantMutation } from 'services/instant';

const CreateInstantModal = ({ isOpen, detail, onClose, refetch }) => {
  const params = useParams();
  const { id: categoryId } = params || {};
  const cancelRef = React.useRef();
  const createInstantMutation = useCreateInstantMutation();
  const updateNewsMutation = useUpdateNewsMutation();
  const [file, setFile] = useState();
  const [isCheckboxText, setIsCheckboxText] = useState(true);
  const [isCheckboxImage, setIsCheckboxImage] = useState(false);
  const [textColor, setTextColor] = useState(detail?.title_color || '#000000');
  const [backgroundColor, setBackgroundColor] = useState(detail?.background_color || '#FFFFFF');
  const [textContent, setTextContent] = useState(detail?.news || '');
  const [videoKey, setVideoKey] = useState(nanoid());

  const { control, handleSubmit, reset, setValue } = useForm({
    // resolver: yupResolver(CategoryFormValidate),
    defaultValues: {
      news: '',
      type: 'TEXT',
      background_color: '',
      title_color: '',
    },
  });

  const [error, setError] = useState({
    files: null,
  });
  const [files, setFiles] = useState([]);
  const [isDragEnter, setIsDragEnter] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'video/*': [],
    },
    onDragEnter: () => {
      setIsDragEnter(true);
    },
    onDragLeave: () => {
      setIsDragEnter(false);
    },
    onDrop: acceptedFiles => {
      setIsDragEnter(false);
      setError({ thumbnailFile: null });
      const newFile = acceptedFiles[0];
      const newFileData = {
        id: nanoid(),
        preview: URL.createObjectURL(newFile),
        file: newFile,
        type: newFile.type.split('/')[0],
      };

      setFiles([newFileData]);
      setVideoKey(nanoid());
    },
  });

  useEffect(() => {
    if (detail) {
      reset({
        id: detail?._id,
        news: detail.news,
        background_color: detail.background_color,
        title_color: detail.title_color,
      });
      setTextColor(detail?.title_color || '#000000');
      setBackgroundColor(detail?.background_color || '#FFFFFF');
      setTextContent(detail?.news || '');

      if (detail?.files && Array.isArray(detail.files)) {
        setFiles(
          detail.files.map(item => ({
            id: nanoid(),
            preview: `${ROOT_API}/${item}`,
            path: item,
          }))
        );
      }
    }
  }, [detail, reset]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${detail ? 'Cập nhật' : 'Tạo'} Khoảnh khắc thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };

  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${detail ? 'Cập nhật' : 'Tạo'} Danh mục thành công`
    );
  };

  const onUploadPhoto = async () => {
    const formData = new FormData();
    const filesUpload = files.filter(item => item.file);
    const filesExist = files.filter(item => !item.file).map(item => item.path);
    let pathFiles = [];

    if (!isEmpty(filesUpload)) {
      filesUpload.map(item => {
        formData.append('files', item.file);
      });

      const response = await uploadMedias(formData);

      pathFiles = response?.data;
    }

    return [...filesExist, ...pathFiles];
  };

  const onSubmit = async values => {
    
    if (values.type !== 'TEXT') {
      if (isEmpty(files)) {
        setError({ files: 'Tải lên ít nhất 1 hình ảnh' });
        return;
      }
    }
   
    const photosPath = await onUploadPhoto();

    if (detail) {
      // updateNewsMutation.mutate(
      //   { ...values, newsId: detail?._id, files: photosPath },
      //   {
      //     onSuccess: () => {
      //       handleSuccess();
      //     },
      //     onError: error => handleError(error),
      //   }
      // );
    } else {
      const dataSubmit = {
        ...values,
        background_color: backgroundColor,
        title_color: textColor,
        news:  photosPath[0] ||values?.news,
        type: isEmpty(files) ? 'TEXT' : files[0].type.toUpperCase(),
      };

      createInstantMutation.mutate(
        { ...dataSubmit },
        {
          onSuccess: () => handleSuccess(),
          onError: error => handleError(error),
        }
      );
    }
  };

  const onRemove = index => {
    setFiles(prev => prev.filter((i, idX) => idX !== index));
  };

  const handleCheckboxChange = type => {
    setIsCheckboxText(type === 'text');
    setIsCheckboxImage(type === 'image');
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => onClose(ModalType.Add)}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW={'600px'}>
        <AlertDialogHeader textTransform="uppercase">{detail ? 'Cập nhật' : 'Tạo'} Khoảnh khắc</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Grid templateColumns="4fr 1fr" gap={6}>
            <Box>
              <FormControl>
                <FormLabel>Tuỳ chọn</FormLabel>
                <HStack spacing={4}>
                  <Checkbox isChecked={isCheckboxText} onChange={() => handleCheckboxChange('text')}>
                    Văn Bản
                  </Checkbox>
                  <Checkbox isChecked={isCheckboxImage} onChange={() => handleCheckboxChange('image')}>
                    Hình ảnh / Video
                  </Checkbox>
                </HStack>
              </FormControl>
              {isCheckboxText && (
                <>
                  <InputController
                    control={control}
                    name="news"
                    label="Nội dung"
                    type="textarea"
                    onChange={e => setTextContent(e.target.value)}
                  />
                  <FormControl display={'flex'} pt={4}>
                    <FormLabel alignContent={'center'}>Chọn màu chữ</FormLabel>
                    <Input width={10} height={10} padding={0} type="color" value={textColor} onChange={e => setTextColor(e.target.value)} />
                  </FormControl>
                  <FormControl display={'flex'} pt={4}>
                    <FormLabel alignContent={'center'}>Chọn màu nền</FormLabel>
                    <Input
                      width={10}
                      height={10}
                      padding={0}
                      type="color"
                      value={backgroundColor}
                      onChange={e => setBackgroundColor(e.target.value)}
                    />
                  </FormControl>
                </>
              )}

              {isCheckboxImage && (
                <FormControl pt={4}>
                  <FormLabel minW="150px">Hình ảnh / Video</FormLabel>
                  <Box
                    {...getRootProps()}
                    cursor="pointer"
                    borderWidth="1px"
                    borderStyle="dashed"
                    borderRadius="12px"
                    padding="55px"
                    textAlign="center"
                    height="210px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <input {...getInputProps()} />
                    <BsUpload size={40} />
                    {isDragEnter ? (
                      <p>Thả ảnh của bạn</p>
                    ) : (
                      <p>
                        <Text as="span" color="red.400">
                          Tải tập tin lên
                        </Text>
                        &nbsp;hoặc kéo và thả
                      </p>
                    )}
                  </Box>
                  {!!error.files && (
                    <Text pt={1} color={'red.500'} fontSize="13px">
                      {error.files}
                    </Text>
                  )}
                </FormControl>
              )}
            </Box>

            {isCheckboxImage && (
              <Box>
                <FormLabel>Xem trước</FormLabel>
                <Box
                  borderWidth="1px"
                  borderStyle="solid"
                  borderRadius="12px"
                  w={138}
                  h={172}
                  background={backgroundColor}
                  overflow="hidden"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                >
                  {files.length > 0 && files[0].type === 'image' ? (
                    <Image src={files[0].preview} w="full" h="full" objectFit="fill" />
                  ) : files.length > 0 && files[0].type === 'video' ? (
                    <video
                      key={videoKey}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      controls
                    >
                      <source src={files[0]?.preview} type={files[0].file.type} />
                    </video>
                  ) : null}
                </Box>
              </Box>
            )}

            {isCheckboxText && (
              <Box>
                <FormLabel>Xem trước</FormLabel>
                <Box
                  borderWidth="1px"
                  borderStyle="solid"
                  borderRadius="12px"
                  w={138}
                  h={172}
                  background={backgroundColor}
                  overflow="hidden"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color={textColor} textAlign="center" paddingX={1} width="100%" whiteSpace="normal">
                    {textContent}
                  </Text>
                </Box>
              </Box>
            )}
          </Grid>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={() => onClose(ModalType.Add)}>
            Hủy
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            isLoading={createInstantMutation.isPending || updateNewsMutation.isPending}
            onClick={handleSubmit(onSubmit)}
          >
            {detail ? 'Cập nhập' : 'Tạo'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateInstantModal;
