import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  FormLabel,
  Box,
  Grid,
  IconButton,
  Image,
  Text,
  GridItem,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import { ModalType, ROOT_API } from 'constants/common';
import { toast } from 'components/Toast';
import { nanoid } from 'nanoid';
import { useUpdateCategoryMutation, useCreateCategoryMutation, useUpdateThumbnailMutation } from 'services/category';
import { CategoryFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { uploadMedias, useUploadMediasMutation } from 'services/media';
import { isEmpty } from 'lodash';
import { useCreateNewsMutation, useUpdateNewsMutation } from 'services/news';

const CreateCategoryModal = ({ isOpen, detail, onClose, refetch }) => {
  const params = useParams();
  const { id: categoryId } = params || {};
  const cancelRef = React.useRef();
  const createNewsMutation = useCreateNewsMutation();
  const updateNewsMutation = useUpdateNewsMutation();
  const updateThumnailMutation = useUploadMediasMutation();
  const [file, setFile] = useState();

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(CategoryFormValidate),
    defaultValues: {
      body: '',
      type: 'image',
      files: '',
    },
  });

  const [error, setError] = useState({
    files: null,
  });
  const [files, setFiles] = useState([]);
  const [isDragEnter, setIsDragEnter] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDragEnter: () => {
      setIsDragEnter(true);
    },
    onDragLeave: () => {
      setIsDragEnter(false);
    },
    onDrop: acceptedFiles => {
      setIsDragEnter(false);
      setError({ thumbnailFile: null });
      setFiles(prev => [
        ...prev,
        ...acceptedFiles.map(file => ({
          id: nanoid(),
          preview: URL.createObjectURL(file),
          file,
        })),
      ]);
    },
  });

  useEffect(() => {
    if (detail) {
      reset({ 
        body: detail.body, 
        id: detail?._id 
    });
    setFiles(
      detail.files.map(item => ({
        id: nanoid(),
        preview: ROOT_API + '/' + item,
        path: item,
      }))
    );
    }
  }, [detail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${detail ? 'Cập nhật' : 'Tạo'} Danh mục thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${detail ? 'Cập nhật' : 'Tạo'} Danh mục thành công`
    );
  };

  const onUploadPhoto = async () => {
    const formData = new FormData();
    const filesUpload = files.filter(item => item.file);
    const filesExist = files.filter(item => !item.file).map(item => item.path);
    let pathFiles = [];

    if (!isEmpty(filesUpload)) {
      filesUpload.map(item => {
        formData.append('files', item.file);
      });

      const response = await uploadMedias(formData);

      pathFiles = response?.data;
    }

    return [...filesExist, ...pathFiles];
  };

  const onSubmit = async values => {
    if (isEmpty(files)) {
      setError({ files: 'Tải lên ít nhất 1 hình ảnh' });
      return;
    }
    const photosPath = await onUploadPhoto();

    if (detail) {
      updateNewsMutation.mutate(
        { ...values, 
          newsId: detail?._id,
          files: photosPath,
        },
        {
          onSuccess: () => {
            handleSuccess();
          },
          onError: error => handleError(error),
        }
      );
    } else {
      const dataSubmit = {
        ...values,
        files: photosPath,
      };
      createNewsMutation.mutate(
        { ...dataSubmit },
        {
          onSuccess: () => handleSuccess(),
          onError: error => handleError(error),
        }
      );
    }
  };

  const onRemove = index => {
    setFiles(prev => prev.filter((i, idX) => idX !== index));
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'} >
          <AlertDialogHeader textTransform="uppercase">{detail ? 'Cập nhật' : 'Tạo'} tin tức</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody >
            <InputController control={control} name="body" label="Thông tin" type='textarea' />
            <FormControl pt={4}>
              <FormLabel minW="150px">Hình ảnh / Video</FormLabel>
              <Box
                {...getRootProps()}
                cursor="pointer"
                borderWidth="1px"
                borderStyle="dashed"
                borderRadius="12px"
                padding="55px"
                textAlign="center"
                height="210px"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <input {...getInputProps()} />
                <BsUpload size={40} />
                {isDragEnter ? (
                  <p>Thả ảnh của bạn</p>
                ) : (
                  <>
                    <p>
                      <Text as="span" color="red.400">
                        Tải tập tin lên
                      </Text>
                      &nbsp;hoặc kéo và thả
                    </p>
                  </>
                )}
              </Box>
              <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                {files?.map((file, index) => (
                  <GridItem w={'100%'} key={file.id} position="relative">
                    <Image src={file.preview} w="full" h="full" objectFit="contain" />
                    <IconButton bg="transparent" position="absolute" top="0" right="0" onClick={() => onRemove(index)}>
                      <BsXCircle size={16} color="red" />
                    </IconButton>
                  </GridItem>
                ))}
              </Grid>
              {!!error.files && (
                <Text pt={1} color={'red.500'} fontSize="13px">
                  {error.thumbnailFile}
                </Text>
              )}
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createNewsMutation.isPending || updateNewsMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {detail ? 'Cập nhập' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateCategoryModal;