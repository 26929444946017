import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const create = data => request.post('/api/v1/voucher/create', data);
export const update = data => request.post('/api/v1/voucher/update', data);
export const getListVoucher = params => request.get('/api/v1/voucher/list', { params });
export const getDetailVoucher = voucherId => request.get(`/api/v1/voucher/detail/${voucherId}`);
export const generate = data => request.post('/api/v1/voucher/voucher-code-generator', data);

// Mutation
export const useCreateVoucherMutation = () => useMutation({ mutationFn: create });
export const useUpdateVoucherMutation = () => useMutation({ mutationFn: update });
export const useVoucherCodeGeneratorMutation = () => useMutation({ mutationFn: generate });

// Query
export const useQueryGetListVoucher = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_LIST_VOUCHER', params], queryFn: () => getListVoucher(params), ...options });
export const useQueryGetDetailVoucher = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_DETAIL_VOUCHER', params], queryFn: () => getDetailVoucher(params), ...options });