import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  FormLabel,
  Box,
  Grid,
  IconButton,
  Image,
  Text,
  GridItem,
  Flex,
  Input,
  FormErrorMessage,
  Avatar,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import InputController from 'components/Form/InputController';
import { ModalType, ROOT_API, STATUS } from 'constants/common';
import { toast } from 'components/Toast';
import { nanoid } from 'nanoid';
import { useUpdateCategoryMutation, useCreateCategoryMutation, useUpdateThumbnailMutation } from 'services/category';
import { CreateAndUpdateKoiFormValidate, CreateVoucherFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { uploadMedias, useUploadMediasMutation } from 'services/media';
import { isEmpty } from 'lodash';
import NumericInputController from 'components/Form/NumericInputController';
import { useCreatePromotionMutation, useUpdatePromotionMutation } from 'services/promotion';
import SelectController from 'components/Form/SelectController';

const CreateAndUpdateModal = ({ isOpen, detail, onClose, refetch }) => {
  const [avatar, setAvatar] = useState();
  console.log(avatar, 'avatar')
  const [avatarPreview, setAvatarPreview] = useState();
  const [file, setFile] = useState();

  const cancelRef = React.useRef();
  const fileInput = React.useRef(null);

  const createPromotionMutation = useCreatePromotionMutation();

  const updateThumnailMutation = useUploadMediasMutation();
  const updatePromotionMutation = useUpdatePromotionMutation();

  const kolStatus = useMemo(() => {
    return Object.keys(STATUS).map(item => {
      return {
        'key': item,
        'label': item
      }

    });
  }, []);
  console.log(kolStatus, 'kolStatus')

  const { control, handleSubmit, reset, setValue, setError, formState: { errors } } = useForm({
    resolver: yupResolver(CreateAndUpdateKoiFormValidate),
    defaultValues: {
      code: '',
      percent: 0,
      amount: 0,
      description: ''
    },
  });
  console.log(errors, 'errors')

  useEffect(() => {
    if (detail) {
      reset({
        title: detail.title,
        percent: detail.percent,
        description: detail.description,
        banner: detail.banner
      });
      const text = detail.banner.split("\\");
      setFile(detail.banner)
      // setFile(
      //   id: nanoid(),
      //   preview: ROOT_API + '/' + detail.banner,
      //   path: detail.banner,
      // )
    }
  }, [detail]);

  const handleSuccess = () => {
    toast.showMessageSuccess(`${detail ? 'Cập nhật' : 'Tạo'} Ưu đãi thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(
      error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `${detail ? 'Cập nhật' : 'Tạo'} Ưu đãi thành công`
    );
  };

  const onSubmit = async values => {
    if (!file) {
      setError('banner', { type: 'custom', message: 'Vui lòng chọn banner.' });
      return;
    }

    if (detail) {
      updatePromotionMutation.mutate(
        {
          ...values,
          promotionId: detail._id
        },
        {
          onSuccess: () => {
            handleSuccess();
          },
          onError: error => handleError(error),
        }
      );
    } else {
      createPromotionMutation.mutate(
        values,
        {
          onSuccess: () => handleSuccess(),
          onError: error => handleError(error),
        }
      );
    }
  };

  const handleFileChange = (e, field) => {
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append('files', e.target.files[0])
      updateThumnailMutation.mutate(
        formData,
        {
          onSuccess: (res) => {
            setFile(e.target.files[0])
            field.onChange(res?.data[0])
          },
        }
      )
    }
  }

  const onChangeAvatar = () => {
    const btn = fileInput?.current;
    if (btn !== null) {
      btn.click();
    }
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'450px'} >
          <AlertDialogHeader textTransform="uppercase">{detail ? 'Cập nhật' : 'Tạo'} KOL</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody >
            <FormControl>
              <FormLabel>
                Chọn ảnh đại diện
              </FormLabel>
              <Avatar
                src={avatarPreview}
                w="100px"
                h="100px"
                borderRadius="12px"
                me="18px"
                mb={3}
                onClick={onChangeAvatar}
              />
              <Input
                hidden
                type="file"
                ref={fileInput}
                onChange={(event) => {
                  setAvatar(event.target.files[0]);
                  const image = URL.createObjectURL(event.target.files[0]);
                  setAvatarPreview(image);
                }}
              />
            </FormControl>
            <InputController
              control={control}
              name="name"
              label="Tiêu đề"
              type='text'
              isRequired
              styleContainer={{ marginTop: "16px" }}
            />
            <InputController
              control={control}
              name="email"
              label="Email"
              isRequired
              styleContainer={{ marginTop: "16px" }}
            />

            <NumericInputController
              control={control}
              name="phone"
              label="Số điện thoại"
              type='text'
              styleContainer={{ marginTop: "8px" }}
            />
            <SelectController
              styleContainer={{ marginTop: "16px" }}
              control={control}
              isRequired
              name="status"
              label="Trạng thái"
              isClearable
              options={kolStatus}
              // onChange={handleCategorySelect}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createPromotionMutation.isPending || updatePromotionMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {detail ? 'Cập nhập' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateAndUpdateModal;