import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {  useQueryGetListMemberChannels } from 'services/channel';
import { CookieStorage } from 'utils/cookie-storage';
import Pagination from 'components/Pagination/Pagination';
import MemberTable from './components/MemberTable';
import { useLocation } from 'react-router-dom';

function ListMemberChannel() {
  const textColor = useColorModeValue('gray.700', 'white');
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const channel_id = query.get('channel_id');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
    channel_id: channel_id,
  });
  const { data: channels, refetch } = useQueryGetListMemberChannels(
    { ...filter },
    { enabled: CookieStorage.isAuthenticated() }
  );

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Danh sách Nhóm Chat
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <MemberTable channels={channels?.data || []} refetch={refetch} channel_id={channel_id}/>
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={channels?.pagination?.page}
              pageLength={channels?.pagination?.pageSize}
              totalRecords={channels?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default ListMemberChannel;
